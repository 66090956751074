.panel-toolbar {
  margin-bottom: 8px;
  .btn-group {
    margin-right: 12px;
  }
  .btn-sm , .btn-group-sm > .btn {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
  }
}
